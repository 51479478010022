import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  return (
    <Layout path={location.pathname} title="מקצה 5 קילומטר">
      <SectionContent title="מקצה 5 קילומטר">
        <div>
          <p>נקודת הזינוק והסיום יהיו בחוף זיקים .</p>
          <p>
            זינוק מחוף זיקים מזרחה על הכביש המוביל לחוף לאחר כ- 1 ק&quot;מ
            מגיעים לצומת טי
          </p>
          <p>
            שמה פונים שמאלה ולאחר מעבר הגשר פונים לשביל ימינה כאשר קיבוץ זיקים
            נמצא משמאלנו.
          </p>
          <p>
            לאחר כחצי ק&quot;מ ניתן לראות את מאגר שקמה מצד ימין. חצי ק&quot;מ
            נוסף מבצעים פרסה וחוזרים באותה דרך לחוף זיקים ושער הסיום.
          </p>
        </div>
        <div>
          <p>
            <iframe
              src="//www.gpsies.com/mapOnly.do?fileId=ovkikzasvnxodoiv"
              width="100%"
              height="400"
              scrolling="no"
              title="sectionIframe"
            ></iframe>
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
